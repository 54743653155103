import React from "react"
import Pagination from 'react-bootstrap/Pagination'

const PaginationBar = ({currentPage, setCurrentPage, pagesAmount}) => {

    const onPageNumberClick = (data) =>{
        const buttonClicked = Number(data.target.name)
        //console.log(buttonClicked)
        if (!isNaN(buttonClicked))
            setCurrentPage(buttonClicked)
    }
    const onPrevClick = () =>{
        if(currentPage>=2)
            setCurrentPage(currentPage-1)
    }
    const onNextClick = () =>{
        if(currentPage<pagesAmount)
            setCurrentPage(currentPage+1)
    }
    const onFirstClick = () =>{
        setCurrentPage(1)
    }
    const onLastClick = () =>{
        setCurrentPage(pagesAmount)
    }
    


    let items= []
    for (let number = 1; number <= pagesAmount; number++) {
        items.push (
        <Pagination.Item  name = {number} key={number} active={number === currentPage} onClick={onPageNumberClick}>
            {number}
          </Pagination.Item>
          ,)
    }

    return (
        <div class="table-responsive">
        <Pagination>
        <Pagination.First onClick={onFirstClick}/>
        <Pagination.Prev onClick={onPrevClick}/>
        {items}
        <Pagination.Next onClick={onNextClick}/>
        <Pagination.Last onClick={onLastClick}/>
        </Pagination>
        </div>
        )
}
export default PaginationBar