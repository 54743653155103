import React, { useState } from "react"
import { graphql } from "gatsby"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Menu from '../components/menu'
import PostsGrid from '../components/posts-grid'
import Footer from '../components/footer'
import Seo from '../components/seo'
import PaginationBar from '../components/pagination-bar'
import {getPagination, getDateSorted} from '../utils/utils'

const Category = ({ data }) => {
    const categoryTitle = data.strapiCategories.name

    const [currentPage, setCurrentPage] = useState(1)
    const allposts = data.allStrapiPosts.edges
    const allStories = data.allStrapiStories.edges
    const postAndStories = getDateSorted(allposts.concat(allStories))
    const itemsPerPage = 9
    const pagesAmount = (Math.ceil(postAndStories.length / itemsPerPage))
    return (
      <>
      <Seo/>
    <Container fluid="md">
        <Row>
        <Col>
            <Menu/>
        </Col>
        </Row>
        <Row id="content-row">
        <Col>
        <Row id="title-row">
        <h2>{categoryTitle}</h2>
        </Row>
        <Row>
        <PostsGrid posts={getPagination(postAndStories,itemsPerPage,currentPage)} />
        </Row>
        <Row id ="pagination-row">
        <PaginationBar currentPage = {currentPage} setCurrentPage={setCurrentPage} pagesAmount={pagesAmount}/>
        </Row>
        </Col>
        </Row>
        <Footer/>
    </Container>
    </>
    )
}


export const query = graphql`
  query CategoryQuery($slug: String!){
    strapiCategories (slug: {eq: $slug}){
        name
    }
    allStrapiPosts (filter:{category:{slug: {eq: $slug}}}){
        edges{
            node{
              id
              title
              slug
              date
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              author {
                name
                slug
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 200)
                    }
                  }
                }
              }
              category {
                name
                slug
              }
              tags {
                name
                slug
              }
            }
        }
    }
    allStrapiStories (filter:{category:{slug: {eq: $slug}}}){
      edges{
          node{
            id
            title
            slug
            date
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            author {
              name
              slug
            }
            category {
              name
              slug
            }
            tags {
              name
              slug
            }
          }
      }
    }
  }
`

export default Category